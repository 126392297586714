.kyc-success-cont{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.kyc-success-cont > img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.kyc-success-cont > p{
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    padding: 0px 10px;
}
