.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  /* margin-right: -0.75rem;
  margin-left: -0.75rem; */
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  margin-left: 10px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  subtext .order-xl-12 {
    order: 12;
  }
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}

.gap-1{
  gap: 1px !important;
}
.gap-2{
  gap: 2px !important;
}
.gap-3{
  gap: 3px !important;
}
.gap-4{
  gap: 4px !important;
}
.gap-5{
  gap: 5px !important;
}
.gap-6{
  gap: 6px !important;
}
.gap-7{
  gap: 7px !important;
}
.gap-8{
  gap: 8px !important;
}
.gap-9{
  gap: 9px !important;
}
.gap-10{
  gap: 10px !important;
}
.gap-12{
  gap: 12px !important;
}
.gap-15{
  gap: 15px !important;
}
.gap-20{
  gap: 20px !important;
}




.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-60 {
  width: 60% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.min-h-100{
  min-height: 100%;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0px !important;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pr-4 {
  padding-right: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pr-5 {
  padding-right: 3rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.pl-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}

.fs-8 {
  font-size: 8px;
}
.fs-8 {
  font-size: 8px !important;
}
.fs-9 {
  font-size: 9px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 1px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24{
   font-size: 24px !important;
}
.fs-25 {
  font-size: 25px !important;
}
.fs-30 {
  font-size: 30px !important;
}

.fw-100{
  font-weight: 100 !important;
}
.fw-200{
  font-weight: 200 !important;
}
.fw-300{
  font-weight: 300 !important;
}
.fw-400{
  font-weight: 400 !important;
}
.fw-500{
  font-weight: 500 !important;
}
.fw-600{
  font-weight: 600 !important;
}
.fw-700{
  font-weight: 700 !important;
}
.fw-800{
  font-weight: 800 !important;
}
.fw-900{
  font-weight: 900 !important;
}
.fw-1000{
  font-weight: 1000 !important;
}

.fw-lighter{
 font-weight: lighter !important;
}
.fw-bold{
 font-weight: bold !important;
}
.fw-bolder{
 font-weight: bolder !important;
}

/* text clasess */
.text-gray{
  color: gray !important;
}

.text-black{
  color: black !important;
}

.text-blue{
  color: blue !important;
}



.text-center{
  text-align: center;
}

/* display clases */
.d-block{
  display: block !important;
}




.border-none{
  border: none !important;
}

.light-background{
  background-color: #f4f4f4;
}

.white-background{
  background-color: white;
}

.brad-10{
  border: 1px solid #0f172a1a;;
}


.lead-radio-btn{
    padding: 12px 30px;
    width: 130px;
    min-width: max-content;
    text-align: center; 
    background: #fff;
    border-radius: 6px;
    /* padding: 10px 12px; */
    border: 1px solid #3399f0;
    cursor: pointer;
    font-size: 12px;
    color: #3399f0;
    font-weight: 600;
    margin: 0 5px 0 0;
    height: 40px;
    text-transform: capitalize;
}

.lead-radio-btn:hover,.lead-radio-btn.selected{
  background: transparent linear-gradient(90deg,#3399f0 0,#44cafc 100%) 0 0 no-repeat padding-box;
  color: #fff;
}
.lead-radio-item{
  display: flex;
   align-items: center;
}
.lead-radio-item label{
   font-size: 14px;
}
.lead-radio-item input{
   accent-color: black;
}
.vkyc-link-btn{
  background-color: transparent;
  color: blue;
  cursor: pointer;
  border: none;
  font-weight: 600;
}

.customAutoComplete {
  position: relative;
  display: inline-block;
  max-height: 150px;
  width: 100%;
  overflow-y: scroll;
  top: 5px;
  border: 1px solid #F2F3F8;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 3px 10px #F2F3F8;
}

.customAutoComplete>div {
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F2F3F8;
  cursor: pointer;
}

.radio-slider-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.radio-slider-switch > input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.radio-slider-switch > .slider-round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.radio-slider-switch > .slider-round:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.radio-slider-switch > input:checked + .slider-round {
  background-color: #2196F3;
}

.radio-slider-switch > input:focus + .slider-round {
  box-shadow: 0 0 1px #2196F3;
}

.radio-slider-switch > input:checked + .slider-round:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
    left: 0;
    right: 4px;
}

.suggestion-item{
  padding: 7px 10px;
  border-bottom: 1px solid lightgray;
  font-size: 12px;
}

.suggestion-item:hover{
  font-size: 12px;
  border-bottom: 1px solid lightgray;
}

.autocomplete-dropdown-container{
  max-height: 150px;
  overflow-y: auto;
}

.cur-pointer{
  cursor: pointer !important;
}
.cur-notallow{
  cursor: not-allowed !important;
}

button:disabled,
button[disabled]{
 cursor: not-allowed;
}

.uploadimgcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.docupload-btn {
  width: 100%;
  height: 180px;
  padding-top: 70px;
  border: none;
  border-radius: 9px !important;
  /* opacity: 0.8000000238418579; */
  background: var(--finance-light, #EFF5FA);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 7.71513rem;
    cursor: pointer;
}

.doc-delete-icon {
  position: absolute;
  right: 10px;
  top: 8px;
  background-color: white;
  z-index: 9;
  padding: 3px 5px;
  border-radius: 50%;
  cursor: pointer;
}

.document-image-cont {
  width: 100%;
  height: 180px;
  border-radius: 9px !important;
  /* opacity: 0.6000000238418579; */
  background: var(--finance-light, #EFF5FA);
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-image {
  border-radius: 5px;
  object-fit: contain;
  height: 90%;
  max-width: 90%;
}

.doc-error {
  position: absolute;
  bottom: 20px;
  width: 90%;
  margin: 0px 20px;
}

.err-red-line{
  height: 2px;
  height: 0.125rem;
  background-color: #E15555;
}

.document-tif{
  cursor: pointer;
display: flex;
align-items: center;
padding: 2px 40px;
color: blue;
text-decoration: none;
text-align: center;
}


/* Rounded sliders */



/* manual css */

.main-header {
  color: #000c4b;
  font-size: 20px;
  font-weight: 700;
}

.sub-header {
  color: #000c4b;
  font-size: 18px;
  font-weight: 700;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.form-group label,.form-label {
  color: #6a6a6a;
  font-size: 10px;
  text-transform: uppercase;
  display: inherit;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.5px;
  margin-bottom: 6px;
  word-spacing: 2px;
}

.form-group input {
  border-radius: 6px;
  height: 40px;
  color: #000;
  font-weight: 500;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  outline: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid gray;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  letter-spacing: 0.6px;
}

.form_input_border{
  border: 1px solid #c2c2c2;
  border-radius: 6px;
}

.err-message {
  color: red;
  font-size: 12px;
  margin-top: 0.5rem;
}

.form-group input::placeholder {
  color: lightgray;
}

.form-group input:focus {
  border: 1px solid #3399f0;
}

.form-group input.err {
  border-color: rgb(185, 74, 72);
}

/* .form-group label.err{
    color: red;
} */

.tcp-icon {
  height: 18px;
  width: 18px;
}

.lead-product-select {
  padding: 0px !important;
  margin: 0px !important;
}

.lead-blue-btn {
  font-size: 11px;
  height: 40px;
  display: inline-block;
  position: relative;
  background: transparent linear-gradient(90deg, #3399f0 0, #44cafc 100%) 0 0
    no-repeat padding-box;
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
  outline: none;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
}

.professional_details_lead_select__value-container,.professional_details_lead_select__placeholder,.professional_details_lead_select__input-container,.professional_details_lead_select__single-value,.professional_details_lead_select__dropdown-indicator {
  padding:0px !important;
  margin: 0px !important;
}

.css-1kkl8z4-control{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.professional_details_lead_select__control--is-focused{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.plan-box{
    padding: 15px;
    height: 100%;
    border-radius: 12px !important;
    border: 1px solid #E6E6E6 !important;
    background:  #FFF;
}

.hor-line {
  height: 0.0625rem;
  opacity: 0.05;
  background: #1D2032;
}

.plan-box  .paymentsContent {
  color: #50495A ;
  font-size: 18px ;
  line-height: normal;
  font-weight: 600;
}
.plan-box  .payemntsTitle {
  color: #50495A ;
  font-size: 12px ;
  line-height: normal;
  font-weight: 400;
}

.text-right {
  text-align: right !important;
}

.plan-box .includeMoratorium {
  color: #000;
  font-size: 0.5625rem;
  line-height: normal;
  font-family: "Graphik Medium";
  text-align: right;
  outline: none;
  border: none;
  background-color: transparent;
}

.selectBtn {
  border-radius: 6.25rem;
  border: 1.5px solid #999;
  background: #FFF;
  padding: 0.75rem 0px ;
  font-size: 1rem ;
  line-height: normal;
  width: 100% ;
  color: #525252 ;
  text-align: center;
  font-family: "Graphik Medium";
  cursor: pointer;
}

.selectedBtn {
  display: flex;
  color: #fff ;
  border-radius: 6.25rem;
  background: #7AA3D7;
  padding: 0.75rem 1rem ;
  width: 100%;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1rem;
  line-height: normal;
  font-family: "Graphik Medium";
  border: none;
  outline: none;
}


.plan-box:hover .selectBtn {
  color: #fff !important;
  border-radius: 100px;
  background: var(--finance-blue, #7AA3D7);
  border: none !important;
}

.plan-box:hover {
  background: #EFF5FA !important;
  border: none !important;
}

.plan-box.selected {
  background: #EFF5FA !important;
}

.w-max-content{
  width: max-content !important;
}

.min-500 {
 min-height: 500px !important;
}

.address-list-container {
  border: 1px solid lightgray;
  padding: 20px;
  border-radius: 12px;
  max-height: 260px;
  overflow-y: auto;
  margin-bottom: 20px;
}
.lead-dob-picker > div {height: 40px;}

.lead-dob-picker > div > input{
  padding: 6px 12px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.lead-dob-picker fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid gray;
  outline: none;
}
.lead-dob-picker fieldset.MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid gray;
  outline: none;
}

.lead-dob-picker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid #3399f0;
}

.file-pass-table-cont > table >tbody> tr > td, .file-pass-table-cont > table >thead>tr>th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  font-size: 12px;
}

.file-pass-table-cont table {
  border-collapse: collapse;
}

.statement-pass-next-btn{
    padding: 6px 23px;
    margin-top: 13px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    cursor: pointer;
}

.start-newapp-text{
    color: #000c4b;
    font-size: 14px;
    font-weight: 700;
}
.app-newapp-text{
    color: #486581;
    font-size: 12px;
    margin-top: 6px;
}

.account-active-toast{
  color: #000c4b;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2rem;
}


.verify-mb-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9px 17px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;
  color: #fff;
  background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
}

#trans-det-check{
    height: 16px;
    width: 16px;
}


























/*   css media query for mobile */

@media (max-width: 576px) {
  .create-lead-mb.w-80 {
    width: 90% !important;
  }

  .create-lead-mb.my-5 {
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  .create-lead-mb.p-4{
      padding: 1.5rem 0.8rem !important;
  }

  .create-lead-mb > .row.px-3.pb-3{
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 16px !important;
  }

  .lead-journey-mb.w-80.p-4.mt-5{
    width: 90% !important;
    padding: 1.5rem 0.8rem !important;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  .lead-journey-mb.w-80.p-4.my-5{
    width: 90% !important;
    padding: 1.5rem 0.8rem !important;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  /* .lead-blue-btn{
    margin-top: 20px;
  } */
   .mb-mt-3{
    margin-top: 1rem;
   }
}

















