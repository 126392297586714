.sidebar{
    width: 250px;
    min-width: 250px;
    border-right: 1px solid lightgray;
   height: 100%;
   overflow-y: auto;
   scroll-behavior: smooth;
}

.customer-sidebar {
  width: 200px;
  min-width: 200px;
  border-right: 1px solid #0f172a1a;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  /* background-color: #f2f2f2;
   */
   background-color: white;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.side-list-item.active,.side-list-item:hover{
  color: #0C7FDA;
  background-color: #E9F5FE;
}

.stepWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    }
    
    .sidebar::-webkit-scrollbar {
      width: 5px;
      /* height: 10px; */
      /* display: none; */
    }
    
    .sidebar::-webkit-scrollbar-track {
      background-color: #eceef2;
      border-radius: 180px;
    }
    
    .sidebar::-webkit-scrollbar-thumb {
      background-color: #9b9b9b;
      border-radius: 180px;
    }

.stepBlock{
    min-height: 65px;
    display: flex;
    gap: 10px;
    position: relative;
   }
  
  .stepBlock .circleWrapper {
    position: relative;
  }

  .stepBlock .circleWrapper img {
    position: relative;
    z-index: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: contain;
    margin: -2px;
    display: block;
}
  
  .selected .circle, .selected img {
    color: black;
  }
  
  .stepBlock:not(:last-child) > .circleWrapper::after {
    content: '';
    width: 0px;
    height: 68px;
    position: absolute;
    top: 0px;
    left: -1px;
    right: 0px;
    margin: auto;
    border-right: 1.5px dashed #959595;
}

.stepBlock.mestages > .circleWrapper::after {
   height: 100px;
}

.mestages{
  min-height: 100px;
}
  
  .selected.stepBlock:not(:last-child) > .circleWrapper::after {
    border-right: 1px solid rgba(154, 212, 80, 1);
  }

  
  .stepBlock:not(:last-child).selected > .circleWrapper::after, .stepBlock:not(:last-child).selected ~ .stepBlock:not(:last-child) > .circleWrapper::after {
    opacity: 0.6;
  }
  
  .stepBlock.activestage{
     color: blue;
  }

  .stepBlock.activestage .circle{
    border: 1px solid blue;
  }

  .circle {
    width: 20px;
    height: 20px;
    line-height: 25px;
    background-color: #FFFFFF;
    color: white;
    border-radius: 50%;
    border: 1px solid #999999;
    cursor: pointer;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin-left: -2px;
    text-align: center;
    position: relative;
    z-index: 1;  
  }

  .mestages .sd-sub-st-cont .circle{
    height: 12px;
    width: 12px;
    margin-left: 0px;
  }

  .stage-label{
    color: #000c4b;
    font-size: 13px;
    font-weight: 500;
    font-family: "Poppins";
  }

  .stepBlock.activestage .stage-label {
    color: blue;
  }

  .side-list{
    list-style-type: none;
    margin-top: 16px;
  }

  .side-list-item{
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
  }

  .sd-sub-st-cont{
    display: flex;
    position: absolute;
    top: 30px;
    left: 30px;
    flex-direction: column;
    gap: 10px;
  }

  .sd-sub-icon{
    width: 12px;
    height: 12px;
  }

 
  
  
/* media query for mobile */
  @media (max-width: 576px) {
     .sidebar{
      /* display: none; */
      position: fixed;
      left: 0;
      /* transform: translateX(-250px); */
      transform: translateX(-250px);
      transition: all 0.8s;
      background-color: #f4f4f4;
      z-index: 11;
     }
     
     .sidebar.mb-sidebar{
      display: block;
      transform: translateX(0px);
      position: fixed;
      left: 0;
      background-color: #f4f4f4;
      z-index: 11;
      transition: all 0.8s;
     }
  }
  