/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: "Poppins";
}

.side-main-container{
    display: flex;
    height: 90vh;
    
}

.side-main-container .main-content{
    flex: 1;
    height: 100%;
    overflow-y: auto;
}