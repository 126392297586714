.login-cont{
    height: 100vh;
    box-sizing: border-box;
}

.login-left{
    /* border: 1px solid red; */
    height: 100%;
    padding: 0px;
}

.login-box{
    margin: 100px;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.lb-input-cont{
    display: flex;
    gap: 10px;
    background-color: #D1E4E6;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
}
.lb-input-cont > input{
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 18px;
    /* font-weight: lighter; */
    font-weight: lighter; 
}


.lb-otp-cont{
    width: 100%;
    color: white;
    /* background-color: #213455; */
    cursor: pointer;
}

/* .lb-otp-cont > div{

} */
.lb-otp-cont >div{
    display: flex;
    justify-content: space-between;
}

.lb-otp-cont >div > input{
    font-size: 20px;
    width: 50px !important;
    height: 50px !important;
    background: #D1E4E6;
    color: black !important;
    border-radius: 4px;
    margin-right: 5px;
    outline: none;
    border: none;
    font-weight: bold;
    /* color: grey !important; */
}

.lb-get-otp-btn{
    width: 100%;
    color: white;
    background-color: #213455;
    padding: 12px 30px;
    border-radius: 4px;
    cursor: pointer;
}
