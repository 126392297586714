.loans-tab{
        margin-right: 25px;
        color: #999999;
        padding: 6px 0px;
        background-color: transparent;
        cursor: pointer;
        font-weight: bold;
       
}

.loans-tab.active{
    color: black;
    border-bottom: 2px solid black;
}

.loans-table{
    margin: 20px 0px;
    border: 1px solid #d8d4d6;
    border-radius: 13px;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
}

.loans-table > thead > tr > th{
    padding: 6px 20px;
    vertical-align: middle;
    text-align: start;     
    border-bottom: 1px solid #d8d4d6;  
}

.loans-table > tbody > tr > td{
    padding: 14px 20px;
    vertical-align: middle;
    text-align: start; 
    border-bottom: 1px solid #d8d4d6;     
}

.loans-table > tbody >tr:last-child > td{
  border-bottom: none;
}

