.lplans-cont{
    display: flex;
    gap: 20px;
    padding: 10px 30px 10px 20px;
    background-color: lightgray;
    margin-top: 12px;
    border-radius: 13px;
    justify-content: space-between;
}

.ldoc-cont{
    display: flex;
    gap: 20px;
    padding: 10px 30px 10px 20px;
    background-color: lightgray;
    margin-top: 12px;
    border-radius: 13px;
    justify-content: space-between;
    flex-direction: column;
}

/* .lplan-item {
    flex: 1;
} */

.ldt-container{
 column-gap: 100px;
 row-gap: 20px;
 margin-top: 30px;
}

.lplan-item:last-child{
    text-align: center;
}

.lpayment-box{
    border: 1px solid lightgray;
    margin-top: 12px;
    background-color: #f4f4f4;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 14px;
    border-radius: 3px;
}

.lp-buttons{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.lp-buttons > button{
    padding: 8px 12px;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
}

.lp-fpay{
    background-color: black;
    color: white; 
}

.lp-cpay{
    background-color: gray;
    color: white;
    border: 1px solid gray;
}

.lp-cpinput{
  border: 1px solid gray;
  outline: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;

}

/* .lp-cpinput::placeholder{
    text-align: center;
} */


/* .lp-schedule > span */


.ld-siglef{
    display: flex;
    justify-content: space-between;
}

.ld-siglef > div:last-child{
  border: 1px solid gray;
  padding: 5px 10px;
  border-radius: 3px;
  min-width: 140px;
  text-align: right;
}

.application-detail .ld-siglef > div:last-child{
    width: 200px;
    height: max-content;
  }

.ld-tab-cont{
    border-bottom: 1px solid gray;
    margin-bottom: 20px;
}

.ld-docr{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.pright35{
    padding-right: 35px;
}


