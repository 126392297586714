.api-loader-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.api-loader-container > img {
    width: 100px;
    height: 100px;
    /* object-fit: cover; */
}