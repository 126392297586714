
.mainbody{
  display: flex;
  justify-content: center;
  height:100%;
}

.loader-container{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

}

.loader-container > img {
  width: 100px;
  height: 100px;
}

.card-container {
  width: 90%;
  min-height: 50vh;
  padding: 20px 7px 20px 7px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.adhar-no-label {
  font-weight: 800;
  font-size: 20px;
}

.adhar-input{
    width: 100%;
    border: none;
    border-bottom: 1px solid rgb(92, 89, 88);;
    padding: 5px 0px ;
    outline: none;
    font-size: 20px;
    color: gray;
    font-weight: bold;
   
}

.captcha-cont{
  display: flex;
}

.verify-btn{
  border: 1px solid lightgray;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  width: max-content;
  height: max-content;
  /* min-width: 40%; */
}

.disable-btn{
  background-color: rgba(153, 153, 153, 1);
  color: black;
}

.enable-btn {
  background-color: black;
  color: white;
}

.enable-btn:hover{
  font-size: 14px;
}

.optverify > div:first-child{
  flex-wrap: wrap;
}

.optverify > div > input {
  font-size: 20px;
  width: 50px !important;
  height: 64px !important;
  background: #f8f5f2;
  color: black !important;
  border-radius: 10px;
  margin-right: 5px;
  outline: none;
  border: 1px solid lightgray;
  font-weight: bold;
  color: grey !important;
}

.addharno-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.otp-cont {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.adhar-consent{
  display: flex;
  gap: 10px;
}

.share-code-cont{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.share-code-cont > input {
  padding: 10px 10px;
  color: black;
  outline: none;
  border: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  font-size: 20px;
  color: gray;
  font-weight: bold;
}

.share-code-cont > label {
  font-weight: 800;
  font-size: 20px;
}

.resend{
  font-weight: 400;
  color: #838F97;
}

.btn-resend-cont{
  display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: wrap;
}


/* hide the arrow of input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}






@media only screen and (max-width: 390px) {
  .optverify > div > input {
    width: 40px !important;
    height: 50px !important;
    font-size: 16px;
    border-radius: 7px;
    margin-bottom: 5px;
  }

  .share-code-cont > input {
    padding: 7px 7px;
    font-size: 16px;
  }
  .share-code-cont > label {
    font-size: 20px;
  }
}



/* For tablets: */
@media only screen and (min-width: 600px) {
  .card-container{
    width: 60%;
   }
}

/* For desktop: */
@media only screen and (min-width: 768px) {
   .card-container{
    width: 60%;
   }
   .mainbody{
    /* height: 60vh; */
   }
}