.modal {
    /* display: none;  */
    position: fixed; 
    z-index: 10; 
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    /* overflow: auto;  */
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
  }

  .lead-limit-popup-content{
    width: 450px;
    height: 400px;
    border-radius: 6px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 35px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .llp-top-box{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 6px;
  }

  .llp-bottom-box{
    display: flex;
    justify-content: flex-end;
  }

  .llp-close-btn{
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 5px;
    cursor: pointer;
  }
  
  .llp-close-btn:hover,
  .llp-close-btn:focus {
    color: #000;
    text-decoration: none;
  }