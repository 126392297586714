.navbar{
    height: 10vh;
    /* background-color: ; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
    padding: 10px 30px;
    border-bottom:1px solid #0f172a1a;
    display: flex;
    align-items: center;
    min-height: 62px;
    justify-content: space-between;
}

.wizr-logo{
    height: 40px;
}

.right-nav{
    display: flex;
    align-items: center;
    gap: 25px;
}

.right-nav > div{
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
}

.mb-hamberger{
    position: fixed;
    top: 14px;
    right: 20px;
}

.mb-hamberger-label{
    display: none;
}

#hamber-check{
    display: none;
}

@media (max-width: 576px) {
    .mb-hamberger-label {
      display: block;
    }

    .navbar{
        justify-content: start;
        gap: 30px;
        padding-left: 15px;
    }
}