
.custmer-btns{
    display: flex;
    gap: 30px;
}

.custmer-btns > button{
   width: 120px
}

.button-43 {
      background-image: linear-gradient(-180deg, #37AEE2 0%, #1E96C8 100%);
      border-radius: .5rem;
      box-sizing: border-box;
      color: #FFFFFF;
      display: flex;
      font-size: 16px;
      justify-content: center;
      padding: 1rem 1.75rem;
      text-decoration: none;
      width: 100%;
      border: 0;
      cursor: pointer;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
    }
    
    .button-43:hover {
        background-image: linear-gradient(-180deg, #1D95C9 0%, #17759C 100%);
      }
      @media (min-width: 768px) {
        .button-43 {
          padding: 1rem 2rem;
        }
    }

.button-62 {
  background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.col-md-5.ld-siglef > div:last-child{
  width: 200px;
  text-align: left;
  overflow-y: auto;
}

.col-md-5.ld-siglef > div:last-child::-webkit-scrollbar {
    display: none;
}



